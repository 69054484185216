import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query FeedingHopeFR {
    heroImage: file(relativePath: {eq: "project-feeding-hope-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Feeding Hope: Nous ne leur fournissons pas seulement de la nourriture .. avec votre soutien, nous nourrissons leurs espoirs." description="Hunger and the different types of malnutrition threaten the lives and health of millions of people around the world. Women and children are the hardest hit. Political instability, proliferation of violent conflicts, climate shocks, social problems are all reasons that can lead to hunger and malnutrition." mdxType="SEO" />
    <CauseHero purpose="feedinghope" title="Feeding Hope" text="Nous ne leur fournissons pas seulement de la nourriture .. avec votre soutien, nous nourrissons leurs espoirs." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`La faim et les multiples formes de malnutrition menacent la vie et la santé de millions de personnes dans le monde. Les femmes et les enfants sont les plus durement touchés. L'instabilité politique, la prolifération des conflits violents et les changements climatiques sont autant de raisons pouvant causer la faim et la malnutrition.`}</p>
      <p><strong parentName="p">{`FEEDING HOPE`}</strong>{` est l'un des plus grands programmes humanitaires de `}<strong parentName="p">{`UMMATY`}</strong>{`: nous fournissons aux réfugiés, aux sans-abri et à tous les types de personnes nécessiteuses une assistance et un soutien en temps de crise pour répondre à leurs `}<strong parentName="p">{`besoins les plus urgents.`}</strong></p>
      <p>{`L'aide apportée va au delà de simples `}<strong parentName="p">{`ration alimentaires`}</strong>{`: les besoins sont minutieusement étudiés, afin de constituer des `}<strong parentName="p">{`paniers alimentaires`}</strong>{` équilibrés et en adéquation avec les habitudes alimentaires de chaque région. En plus de cela, des `}<strong parentName="p">{`kits sanitaires, couvertures et vêtements`}</strong>{` sont également distribués pour répondre aux besoins urgents.`}</p>
      <p>{`Dans les montagnes isolées par les neiges hivernales, dans les villages touchés par la sécheresse ou encore dans les camps de réfugiés, les équipes de UMMATY se déplacent pour être au plus près des nécessiteux. Grâce à vos dons, nous leur apportons un espoir et leur rappelons qu'ils ont des frères et sœurs à travers le monde qui les soutiennent et qui continuent d'œuvrer afin de leur offrir un avenir meilleur.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      